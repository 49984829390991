import { Injectable, EventEmitter, NgZone } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { SocialAuthInfo } from '../models/social-auth-info.model';
import { getGoogleClientID } from '@app/shared/utils/url.utils';
import { AkitaRouterQuery } from '@app/akita/router/state/router.query';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import {
  /*   logOneTapDismissed,
  logOneTapDisplayed, */
  logOneTapSuccess,
} from '@app/core/services/google-analytics.lazy';
import { AkitaAuthQuery } from '../state/auth.query';

const MESSAGE_TYPE_SUCCESS = 'google-auth-success';
const MESSAGE_TYPE_ERROR = 'google-auth-error';
const POPUP_WIDTH = 600;
const POPUP_HEIGHT = 600;
const DELAY_CEHCK_WINDOW_CLOSED_MS = 500;

const REDIRECT_PATH = 'auth/google-sign-in';
const WINDOW_REDIRECT_PATH = 'oauth/google.success.html';

// Google's OAuth 2.0 endpoint for requesting an access token
const GOOGLE_OAUTH2_ENDPOINT = 'https://accounts.google.com/o/oauth2/v2/auth';

/**
 * Class to handle the API Calls
 */
@Injectable({
  providedIn: 'root',
})
export class SignWithGoogleService {
  public isBrowser: boolean;

  private windowObjectReference: Window | null;
  private previousUrl: string | null;
  private oneTapInizialized = false;

  private readonly oneTapMonitor: EventEmitter<{
    clientId: string;
    credential: string;
    select_by: string;
  }>;

  constructor(
    private readonly zone: NgZone,
    private readonly akitaRouterQuery: AkitaRouterQuery,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly akitaAuthQuery: AkitaAuthQuery
  ) {
    this.isBrowser = this.akitaRouterQuery.isBrowser;
    this.oneTapMonitor = new EventEmitter();

    this.windowObjectReference = null;
    this.previousUrl = null;

    this.checkGoogleOneTap(
      !this.akitaAuthQuery.isLoggedIn &&
        this.akitaRouterQuery.viewStep === 'CHECKOUT_SESSION'
    );
  }

  public signInOauthV2InWindow(): Observable<SocialAuthInfo | null> {
    return new Observable((observer: Observer<SocialAuthInfo | null>) => {
      this.zone.runOutsideAngular(() => {
        const requestURL = this.akitaRouterQuery.requestURL;
        const redirectURL = `${window.location.origin}/${WINDOW_REDIRECT_PATH}`;
        this.openSignInWindow(
          observer,
          `/oauth/google.html?clientId=${getGoogleClientID()}&requestURL=${requestURL}&redirectURL=${redirectURL}`,
          ''
        );
      });
    });
  }

  private openSignInWindow(
    observer: Observer<SocialAuthInfo | null>,
    url: string,
    name: string
  ): void {
    this.zone.runOutsideAngular(() => {
      const messageObserver = this.receiveMessage(observer);
      const popupCloseObserver = this.onPopupClose(observer);

      // remove any existing event listeners
      window.removeEventListener('message', messageObserver.bind(this));

      // Fixes dual-screen position, Most browsers, Firefox
      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX;
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height;

      const systemZoom = width / window.screen.availWidth;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const left = ((width - POPUP_WIDTH) * 0.5) / systemZoom + dualScreenLeft;
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const top = ((height - POPUP_HEIGHT) * 0.5) / systemZoom + dualScreenTop;

      // window features
      const strWindowFeatures = `toolbar=no, menubar=no, width=${POPUP_WIDTH}, height=${POPUP_HEIGHT}, top=${top}, left=${left}`;

      if (!this.windowObjectReference || this.windowObjectReference.closed) {
        // if the pointer to the window object in memory does not exist
        // or if such pointer exists but the window was closed
        this.windowObjectReference = window.open(url, name, strWindowFeatures);
      } else if (this.previousUrl !== url) {
        // if the resource to load is different,
        // then we load it in the already opened secondary window and then
        // we bring such window back on top/in front of its parent window.
        this.windowObjectReference = window.open(url, name, strWindowFeatures);
        if (this.windowObjectReference && (this.windowObjectReference as any)?.focus) {
          (this.windowObjectReference as any).focus();
        }
      } else {
        // else the window reference must exist and the window
        // is not closed; therefore, we can bring it back on top of any other
        // window with the focus() method. There would be no need to re-create
        // the window or to reload the referenced resource.
        if ((this.windowObjectReference as any)?.focus) {
          (this.windowObjectReference as any).focus();
        }
      }

      // assign the previous URL
      this.previousUrl = url;

      // add the listener for receiving a message from the popup
      window.addEventListener('message', messageObserver.bind(this), false);

      if (this.windowObjectReference) {
        this.windowObjectReference.removeEventListener(
          'unload',
          popupCloseObserver.bind(this)
        );
        this.windowObjectReference.addEventListener(
          'unload',
          popupCloseObserver.bind(this)
        );
      }
    });
  }

  private onPopupClose(observer: Observer<SocialAuthInfo | null>): () => void {
    return () => {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          if (this.windowObjectReference && this.windowObjectReference.closed) {
            observer.error({
              error: 'popup_closed_by_user',
              message: 'error_social_auth_dialog_closed',
            });
            observer.complete();
          }
        }, DELAY_CEHCK_WINDOW_CLOSED_MS);
      });
    };
  }

  private receiveMessage(
    observer: Observer<SocialAuthInfo | null>
  ): (event: MessageEvent<any>) => void {
    return (event: MessageEvent<any>): void => {
      this.zone.runOutsideAngular(() => {
        if (event.origin !== window.location.origin) {
          return;
        }

        const { data } = event;
        if (data.type === MESSAGE_TYPE_ERROR) {
          let message = '';
          if (data?.error === 'popup_closed_by_user') {
            message = 'error_social_auth_dialog_closed';
          }

          observer.error({
            error: data?.error || '',
            message: message,
          });
          observer.complete();
        } else if (data.type === MESSAGE_TYPE_SUCCESS) {
          if (!data?.error && data?.data?.id_token) {
            observer.next({
              secret: data.data.id_token,
            });
            observer.complete();
          } else {
            observer.error({
              error: data?.error || '',
              message: 'error_social_auth_invalid_domain',
            });
            observer.complete();
          }
        }
      });
    };
  }

  public signInOauthV2(): Observable<SocialAuthInfo | null> {
    const baseURL = this.akitaRouterQuery.getCanonicalBaseURL();
    const requestURL = this.akitaRouterQuery.requestURL;
    const redirectURL = `${baseURL}/${REDIRECT_PATH}`;

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    const form = (document as any)?.createElement('form');
    form?.setAttribute('method', 'GET'); // Send as a GET request.
    form?.setAttribute('action', GOOGLE_OAUTH2_ENDPOINT);

    // Parameters to pass to OAuth 2.0 endpoint.
    const params: { [name: string]: string } = {
      client_id: getGoogleClientID(),
      redirect_uri: redirectURL,
      response_type: 'id_token token',
      include_granted_scopes: 'true',
      // eslint-disable-next-line deprecation/deprecation
      state: `${btoa(requestURL)}`,
      scope: 'https://www.googleapis.com/auth/userinfo.profile',
      nonce: 'ea2fd7ca249a35b2f4b4a6ce4658f75f',
      use_fedcm_for_prompt: 'true',
    };

    // Add form parameters as hidden input values.
    for (const paramName of Object.keys(params || {})) {
      const input = (document as any)?.createElement('input');
      input?.setAttribute('type', 'hidden');
      input?.setAttribute('name', paramName);
      input?.setAttribute('value', params[paramName]);
      form?.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document?.body?.appendChild(form);
    form?.submit();

    return of(null);
  }

  private checkGoogleOneTap(requestToken?: boolean | null): void {
    this.zone.runOutsideAngular(() => {
      if (this.isBrowser) {
        try {
          if (
            typeof google !== 'undefined' &&
            (google as any)?.accounts &&
            (google as any)?.accounts?.id
          ) {
            this.oneTapSignInInitialize(requestToken);
          } else {
            (window as any).onGoogleLibraryLoad = () => {
              this.oneTapSignInInitialize(requestToken);
            };
          }
        } catch (error) {
          // Google not initialized (network error? E2E test?)
          console.warn(error);
        }
      }
    });
  }

  private oneTapSignInInitialize(requestToken?: boolean | null): void {
    this.zone.runOutsideAngular(() => {
      try {
        if (!this.oneTapInizialized && typeof google !== 'undefined') {
          const response = (google as any).accounts.id.initialize({
            client_id: getGoogleClientID(),
            callback: this.oneTapSignInCallback.bind(this),
            state_cookie_domain: 'popsy.app',
            nonce: 'ea2fd7ca249a35b2f4b4a6ce4658f75f',
            cancel_on_tap_outside: false,
            auto_select: false,
            use_fedcm_for_prompt: 'true',
          });
          if (response) {
            this.oneTapInizialized = true;
          }

          if (requestToken) {
            this.oneTapSignIn(true);
          }
        }
      } catch (error) {
        // Google not initialized (network error? E2E test?)
        console.warn(error);
      }
    });
  }

  public oneTapSignIn(skipRetry?: boolean | null): void {
    this.zone.runOutsideAngular(() => {
      try {
        if (
          this.oneTapInizialized &&
          typeof google !== 'undefined' &&
          (google as any)?.accounts &&
          (google as any)?.accounts?.id &&
          (google as any)?.accounts?.id?.prompt
        ) {
          (google as any).accounts.id.prompt();
        } else {
          if (!skipRetry && !this.oneTapInizialized) {
            this.checkGoogleOneTap(true);
          }
        }
      } catch (error) {
        // Google not initialized (network error? E2E test?)
        console.warn(error);
      }
    });
  }

  public disableOneTapAutoSelect(): void {
    this.zone.runOutsideAngular(() => {
      try {
        if (typeof google !== 'undefined' && (google as any)?.accounts?.id) {
          (google as any).accounts.id.disableAutoSelect();
        }
      } catch (error) {
        // Google not initialized (network error? E2E test?)
        console.warn(error);
      }
    });
  }

  private oneTapSignInCallback(
    data?: { clientId: string; credential: string; select_by: string } | null
  ): void {
    this.zone.runOutsideAngular(() => {
      if (data) {
        logOneTapSuccess(this.googleAnalyticsService.logEventWrapper, data.select_by);
        this.oneTapMonitor.emit(data);
      }
    });
  }

  public get observeOneTap(): Observable<{
    clientId: string;
    credential: string;
    select_by: string;
  }> {
    return this.oneTapMonitor.asObservable();
  }
}
